import { lazy } from "react";

import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/experiments/$experimentId")({
  component: () => <Experiment />,
  params: {
    parse: (params) => ({
      experimentId: z.custom<ExperimentIds>().parse(params.experimentId),
    }),
    stringify: ({ experimentId }) => ({ experimentId }),
  },
});

function Experiment() {
  const { experimentId } = Route.useParams();

  const Experiment = ExperimentComponents[experimentId];

  if (!Experiment) {
    return (
      <div className="prose prose-neutral flex size-full max-w-full justify-center pt-8 ring-1">
        <h1>Experiment not found</h1>
      </div>
    );
  }

  return <Experiment />;
}

// Be careful to not rename routes if they are already being used in other apps or projects, for backwards compatibility, just add a new route.
type ExperimentIds =
  | "magic-trail"
  | "map-blops-v0"
  | "follow-arrow"
  | "boxes"
  | "boxes-physics"
  | "sound-slider"
  | "leva";

//
// -------------------------------------------------------------------
// Lazy loaded experiments
//

const ExperimentComponents = {
  "magic-trail": lazy(() =>
    import("@/experiments/magic-trail").then((module) => ({
      default: module.MagicTrailExperiment,
    })),
  ),
  "map-blops-v0": lazy(() =>
    import("@/experiments/map-blops-v0").then((module) => ({
      default: module.MapBlospV0Experiment,
    })),
  ),
  "follow-arrow": lazy(() =>
    import("@/experiments/follow-arrow").then((module) => ({
      default: module.FollowArrowExperiment,
    })),
  ),
  boxes: lazy(() => import("@/experiments/boxes")),
  "boxes-physics": lazy(() => import("@/experiments/boxes-physics")),
  "sound-slider": lazy(() => import("@/experiments/sound-slider")),
  leva: lazy(() => import("@/experiments/leva")),
};
